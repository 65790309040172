<script>
  import game from "../store/game.store";
</script>

<style>
  header {
    height: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .group {
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    background: -webkit-linear-gradient(top, #4dff00 0%, #918516 100%);
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    border: 4px solid rgb(0, 0, 0);
  }

  .group-value {
    font-size: 40px;
  }

  .moves {
    margin-right: 10px;
  }

  .score {
    justify-self: end;
    min-width: 200px;
    text-align: right;
  }

  .title {
    color: rgb(255 232 31);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
    font-size: 48px; /* Larger size for emphasis */
    flex-grow: 1;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    .group {
      padding: 6px 12px;
      border-radius: 12px;
    }

    .group-value {
      font-family: 'Star Jedi', sans-serif;
      font-size: 30px;
    }

    .score {
      font-family: 'Star Jedi', sans-serif;
      min-width: 150px;
    }
  }
</style>

<header>
  <div class="group moves">
    <div class="group-label">Moves</div>
    <div class="group-value">{$game.moves}</div>
  </div>
  <div class="title">WIF HAT Crush</div>
  <div class="group score">
    <div class="group-label">score</div>
    <div class="group-value">{$game.score}</div>
  </div>
</header>
