<script>
  import Board from "./Board.svelte";
  import GameHeader from "./GameHeader.svelte";
  import GameFooter from "./GameFooter.svelte";
  import GameOverModal from "./GameOverModal.svelte";
  import game from "../store/game.store";

  function getInitialBoardSize() {
    const docWidth = document.body.clientWidth;
    const docHeight = document.body.clientHeight;
    let rows = 8;
    let columns = 7;

    if (docWidth >= 700) {
      columns = 10;
    } else if (docWidth >= 580) {
      columns = 8;
    }

    return {
      rows,
      columns
    };
  }

  const moves = 20;
  const { rows, columns } = getInitialBoardSize();

  game.init(rows, columns, moves);

  let showModal = false;
  let bestScore;
  let score;

  function handleGameOver(event) {
    score = $game.score;
    const prevBest = localStorage.getItem("best-score") || 0;
    const newBest = Math.max(score, parseInt(prevBest, 10));

    localStorage.setItem("best-score", newBest);
    bestScore = newBest;
    showModal = true;
  }

  function handlePlayAgain() {
    game.reset();
    showModal = false;
  }
</script>

<style>


  .layout {
    display: flex;
    justify-content: space-between; /* Aligns children (main and side-texts) horizontally */
    align-items: center; /* Centers children vertically */
    height: 100%;
    max-width: 1200px;
    margin: auto;
  }

  main {
    flex-grow: 1; /* Allows main to take up available space */
    display: flex;
    flex-direction: column;
    max-height: 1000px;
  }

  .side-text {
    width: 200px; /* Fixed width for side containers */
    color: white;
    text-align: center;
    padding: 20px;
    border-radius: 20px;
    background-color: #112244; /* Example background color */
  }

  .left {
    margin-right: 20px;
  }

  .right {
    margin-left: 20px;
  }

  @media only screen and (max-width: 768px) {
    .side-text {
      display: none; /* Hide side containers on small devices */
    }
  }
</style>

<div class="layout">
 
  <main>
    <GameHeader />
    <Board on:game-over={handleGameOver} />
    <GameFooter />
    <GameOverModal
      {score}
      {bestScore}
      visible={showModal}
      on:play-again={handlePlayAgain} />
  </main>

</div>


